import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useMemo, useState } from 'react';
import Climate from '@crehana/compass.icons.logos/Climate';
import Learning from '@crehana/compass.icons.logos/Learning';
import Performance from '@crehana/compass.icons.logos/Performance';
import Recognition from '@crehana/compass.icons.logos/Recognition';
import Building from '@crehana/compass.icons.outline/Building';
import Stats from '@crehana/compass.icons.outline/Stats';
import useTalentRouter from "../../../shared/hooks/useTalentRouter";
import { urlsThatShouldBeOpenInNewTab } from "./constants";
import { getLearningUrls } from "./urls";
import useCollaboratorTranslations from "./useCollaboratorTranslations";
import useLearningRoutes from "./useLearningRoutes";
import { ModuleCode, useUtils } from "./utils";
var useMenuLinks = function useMenuLinks(_ref) {
  var talentModules = _ref.talentModules,
    organizationSlug = _ref.organizationSlug,
    _ref$isLearningModule = _ref.isLearningModuleActive,
    isLearningModuleActive = _ref$isLearningModule === void 0 ? false : _ref$isLearningModule,
    handleUrl = _ref.handleUrl,
    pathname = _ref.pathname,
    _ref$defaultSelectedM = _ref.defaultSelectedModule,
    defaultSelectedModule = _ref$defaultSelectedM === void 0 ? ModuleCode.Products : _ref$defaultSelectedM,
    productPlatform = _ref.productPlatform,
    userEmail = _ref.userEmail,
    isProduction = _ref.isProduction;
  var _useUtils = useUtils({
      organizationSlug: organizationSlug
    }),
    getProductsLinks = _useUtils.getProductsLinks,
    getSubmodules = _useUtils.getSubmodules;
  var talentRouter = useTalentRouter({
    isProduction: isProduction
  });
  var _useCollaboratorTrans = useCollaboratorTranslations(),
    t = _useCollaboratorTrans.t;
  var _useLearningRoutes = useLearningRoutes({
      isLearningModuleActive: isLearningModuleActive,
      organizationSlug: organizationSlug,
      productPlatform: productPlatform,
      email: userEmail
    }),
    learningRoutes = _useLearningRoutes.links;
  var _useState = useState(defaultSelectedModule),
    _useState2 = _slicedToArray(_useState, 2),
    selectedModuleCode = _useState2[0],
    setSelectedModuleCode = _useState2[1];
  var learningUrls = useMemo(function () {
    return getLearningUrls(organizationSlug);
  }, [organizationSlug]);
  var productsLinks = getProductsLinks({
    talentModules: talentModules,
    isLearningModuleActive: isLearningModuleActive
  });

  // Check on first page load for selectedModule. This is important and useful when the user gets redirected to this page from another page
  useEffect(function () {
    if (learningUrls.find(function (r) {
      var regExp = new RegExp(r);
      return regExp.test(pathname);
    })) {
      setSelectedModuleCode(ModuleCode.ELearning);
    }
  }, [learningUrls, pathname]);
  var getModuleLabelByCode = function getModuleLabelByCode(code) {
    var _talentModules$find;
    return (_talentModules$find = talentModules.find(function (m) {
      return m.code === code;
    })) === null || _talentModules$find === void 0 ? void 0 : _talentModules$find.label;
  };
  var getCurrentHeader = function getCurrentHeader(_moduleCode) {
    switch (_moduleCode) {
      case ModuleCode.Recognition:
        return {
          title: getModuleLabelByCode(ModuleCode.Recognition) || t('MODULE_RECOGNITION'),
          icon: Recognition
        };
      case ModuleCode.Performance:
        return {
          title: getModuleLabelByCode(ModuleCode.Performance) || t('MODULE_PERFORMANCE'),
          icon: Performance
        };
      case ModuleCode.Climate:
        return {
          title: getModuleLabelByCode(ModuleCode.Climate) || t('MODULE_CLIMATE'),
          icon: Climate
        };
      case ModuleCode.ELearning:
        return {
          title: getModuleLabelByCode(ModuleCode.ELearning) || t('MODULE_LEARNING'),
          icon: Learning
        };
      case ModuleCode.Company:
        return {
          title: getModuleLabelByCode(ModuleCode.Company) || t('MODULE_COMPANY'),
          accentedColor: false,
          icon: Building
        };
      case ModuleCode.Analytics:
        return {
          title: getModuleLabelByCode(ModuleCode.Analytics) || t('MODULE_ANALYTICS'),
          accentedColor: false,
          icon: Stats
        };
      case ModuleCode.Products:
        return;
      default:
        return;
    }
  };
  var currentHeader = selectedModuleCode !== null ? getCurrentHeader(selectedModuleCode) : undefined;
  var onClickItem = function onClickItem(route) {
    var isModuleCodeEnum = Object.values(ModuleCode).includes(route);
    if (isModuleCodeEnum) {
      setSelectedModuleCode(route);
    } else {
      if (productPlatform === 'talent') {
        talentRouter.navigateTo({
          url: route,
          emailUser: userEmail
        });
      } else {
        // NOTE: Keep in my that we do not consider the use of iFrames here because this code is executed at the learning platform directly
        // Handle external link url to talent platform
        if (route.startsWith('https')) {
          if (urlsThatShouldBeOpenInNewTab.includes(route)) {
            window.open(route, '_blank');
          } else {
            window.location.href = route;
          }
        }
        // Handle local routing
        else {
          var routeSlug = route.replace('[slug]', organizationSlug);
          if (handleUrl) {
            handleUrl(routeSlug);
          } else {
            window.location.href = routeSlug;
          }
        }
      }
    }
  };
  var menuLinks = useMemo(function () {
    if (selectedModuleCode === ModuleCode.Products) {
      return productsLinks;
    }
    if (selectedModuleCode === ModuleCode.ELearning) {
      return learningRoutes.map(function (route) {
        return _objectSpread(_objectSpread({}, route), {}, {
          arrowDefault: false,
          decoratedIcon: false,
          tooltip: route.tooltip
        });
      });
    }
    return getSubmodules({
      talentModules: talentModules,
      moduleCode: selectedModuleCode
    });
  }, [selectedModuleCode, learningRoutes, talentModules, getSubmodules, productsLinks]);
  var currentSingleRoute = useMemo(function () {
    var homeUrl = productsLinks[0].url;

    // Check if we are at home
    if (pathname.includes(homeUrl)) {
      return productsLinks[0];
    }
    var flatUrls = menuLinks.map(function (link) {
      if (link.nested && link.nested.length > 0) {
        return link.nested;
      }
      return link;
    }).flat();
    var knowledgeHubLink = flatUrls.find(function (url) {
      return url.code === 'LEARN_KNOWLEDGE_HUB';
    });
    var knowledgeHubSpecialCasePattern = new RegExp('^/org/[a-zA-Z0-9-]+/learn/content/[a-zA-Z0-9-]+/$');
    var tracksLink = flatUrls.find(function (url) {
      return url.code === 'LEARN_TRACKS';
    });
    var trackSpecialCasePattern = new RegExp('^/org/[a-zA-Z0-9-]+/learn/my-courses/tracks/[a-zA-Z0-9-]+/$');
    if (trackSpecialCasePattern.test(pathname)) return tracksLink;

    // It's very important to check first the special case for the knowledge hub
    // The thing here is they want a temporary way to access to knowledge hub
    // by other URLS that match the pattern above
    if (knowledgeHubSpecialCasePattern.test(pathname)) {
      return knowledgeHubLink;
    }
    return flatUrls.find(function (link) {
      return typeof link.url === 'string' && link.url.includes(pathname);
    });
  }, [menuLinks, productsLinks, pathname]);
  return {
    backToUrl: currentHeader ? ModuleCode.Products : '',
    header: currentHeader,
    menuLinks: menuLinks,
    onClickItem: onClickItem,
    currentSingleRoute: currentSingleRoute,
    footerLinks: undefined
  };
};
export default useMenuLinks;